/* eslint-disable jsx-a11y/anchor-is-valid,  jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-console */
import React, {useContext, useEffect, useState} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {RightOutlined, LeftOutlined} from '@ant-design/icons';

import logger from '../../logger';
import {AppContext} from '../../context/AppContext';
import {client} from '../../graphql/queries/client';
import {setNewEntity} from '../../utils/utilHelpers';
import COLORS from '../../theme/colors';
import useScrollWidth from './useScrollWidth';

const StyledEntityListContainer = styled.div`
  width: vh;
  height: 43px;
`;

const StyledEntityList = styled.div`
  /* display: inline-block; causes roll-up into header? */
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  height: 100%;

  align-items: center;
  text-align: center;
  h5 {
    display: inline-block;
    /* margin: 0 10px; does not work; margin-collapse?
    * so we have to call them out explicitly.
    */
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
  & .entityribbon-title {
    display: flex;
    margin-right: 5px;
  }
  & .entityribbon-item {
    border-bottom: 3px;
    border-style: solid;
    border-color: white;
    & > a {
      color: ${COLORS['@primary-navy']};
    }
  }
  & .entityribbon-item__selected {
    font-weight: bold;
    border-bottom: 3px;
    border-color: ${COLORS['@primary-color']};
    border-style: solid;
  }
  & .entityribbon-menu {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  & .entityribbon-menu::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    background-color: #aaa;
  }
  & .entityribbon-menu::-webkit-scrollbar-thumb {
    background-color: ${COLORS['@header-background']};
  }
  & .arrow {
    width: 30px;
    margin: 10px;
    outline: none;
    cursor: pointer;
    color: ${COLORS['@primary-color']};
  }
`;

const EntityRibbon = () => {
  const {state, dispatch} = useContext(AppContext);
  const {entitiesList, selectedEntityId, selectedPortfolioId} = state;
  const scrollWidths = useScrollWidth();
  const [isListOverflown, setListOverflown] = useState(false);

  logger.trace({
    method: 'entityRibbon/logger',
    entitiesList,
    selectedEntityId,
    selectedPortfolioId
  });

  const isOverflown = () => {
    const elementByClass = document.getElementsByClassName('entityribbon-menu') || [];
    const element = elementByClass[0];
    return element?.scrollWidth > element?.clientWidth;
  };

  useEffect(() => {
    const overflow = isOverflown();
    setListOverflown(overflow);
  }, [entitiesList, scrollWidths?.scrollWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!entitiesList || entitiesList.length <= 1) return null;

  const handleSelection = async (event, entityId) => {
    event.preventDefault();
    console.log('EntityRibbon/handleSelection', entityId);
    logger.trace({
      method: 'handleSelection',
      targetId: entityId,
      firstEntity: entitiesList[0]?.entity
    });
    const portfolioIndex = R.findIndex(
      portfolio => R.prop('entity', portfolio) === entityId,
      state.clientInfo.portfolios
    );
    if (portfolioIndex < 0) {
      logger.trace({
        method: 'EntityRibbon/handleSelection',
        message: 'portfolioIndex <0',
        entityId
      });
    }
    const entityData = {
      entity: entityId,
      asOfDatesList: state.clientInfo.portfolios[portfolioIndex].asOfDatesList,
      portfolioIndex,
      portfolioId: `${entityId}:${state.selectedAsOfDate}`,
      displayName: state.clientInfo.portfolios[portfolioIndex].displayName,
      asOfDate: state.selectedAsOfDate
    };
    logger.debug({
      method: 'handleSelection',
      entityData
    });
    setNewEntity(entityData, dispatch);
    await client.resetStore();
    logger.debug({
      method: 'EntityRibbon:post-resetStore',
      entity: state.selectedEntityId,
      portfolioId: state.selectedPortfolioId
    });
  };

  const leftScroll = () => {
    const left = document.querySelector('.entityribbon-menu');
    left.scrollBy(-200, 0);
  };
  const rightScroll = () => {
    const right = document.querySelector('.entityribbon-menu');
    right.scrollBy(200, 0);
  };

  /**
   * @function renderEntityList
   * @param {array} list of entity objects
   *  displayName: {string} entity label
   *  portfolioId {string} linked portfolio
   * @question - spacing between entity names?
   * @question - display styling?
   *  - className entity-list
   *  - flexbox justify-left
   */

  const renderEntityList = _entities => {
    return R.map(entityItem => {
      return (
        <div
          style={{marginLeft: '10px', marginRight: '10px', alignItems: 'center'}}
          key={entityItem.ribbonName}
          className={`entityribbon-item  ${
            state.selectedEntityId === entityItem.entity ? 'entityribbon-item__selected' : ''
          }`}
        >
          <a
            id={entityItem.ribbonName}
            onClick={event => handleSelection(event, entityItem.entity)}
          >
            {entityItem.ribbonName}
          </a>
        </div>
      );
    })(_entities);
  };

  return (
    <StyledEntityListContainer className="entityribbon-container">
      <StyledEntityList className="entityribbon">
        <h5
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'start',
            alignItems: 'center'
          }}
          data-testid="entityribbon-flexcontainer"
          className="entityribbon-flexcontainer"
        >
          <div className="entityribbon-title">Portfolio:</div>

          {isListOverflown ? (
            <div className="arrow" onClick={leftScroll}>
              <LeftOutlined />
            </div>
          ) : (
            <div />
          )}

          <div style={{height: '100%', alignItems: 'center'}} className="entityribbon-menu">
            {renderEntityList(entitiesList)}
          </div>
          {isListOverflown ? (
            <div className="arrow" onClick={rightScroll}>
              <RightOutlined />
            </div>
          ) : (
            <div />
          )}
          <div
            className="entity-ribbon-spacer"
            style={{
              width: '100px',
              height: '52px'
            }}
          />
        </h5>
      </StyledEntityList>
    </StyledEntityListContainer>
  );
};

export default EntityRibbon;
